import Notify from "@/notifications/Notify"

let key = 0
const closeTime = 5000
const debounce = 100
const maxItems = 3

export default {
  install(Vue) {
    const notify = {
      notifications: Vue.observable({ items: [] }),
      show(title, text, type) {
        if (this.notifications.items.length >= maxItems) {
          this.notifications.items.shift()
        }
        let notify = { title, text, type, key: key }
        key++
        setTimeout(() => {
          this.notifications.items.push(notify)
        }, debounce)
        setTimeout(() => {
          this.closeNotify(notify)
        }, closeTime)
      },
      closeNotify(notify) {
        let index = this.notifications.items.indexOf(notify)
        if (index >= 0) {
          this.notifications.items.splice(index, 1)
        }
      },
      showError(title, text) {
        this.show(title, text, "error")
      },
      showWarn(title, text) {
        this.show(title, text, "warning")
      },
      showSuccess(title, text) {
        this.show(title, text, "success")
      },
      showInfo(title, text) {
        this.show(title, text, "info")
      }
    }
    Vue.component("Notify", Notify)
    Vue.prototype.$notify = notify
    Vue.$notify = notify
  }
}
