<template>
  <div>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon
        v-if="isLoggedIn"
        @click="navigationDrawer = !navigationDrawer"
      />
      <v-toolbar-title>
        <v-img
          max-width="100"
          src="@/assets/logo-white.png"
        />
      </v-toolbar-title>

      <v-spacer />


      <v-chip
        v-if="userExist"
        class="ma-2"
        color="primary"
      >
        <v-avatar left>
          <v-icon>mdi-account-circle</v-icon>
        </v-avatar>
        {{ getUser.userName }}
      </v-chip>


      <v-btn
        icon
        @click="toggleTheme"
      >
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="logout"
      >
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-if="isLoggedIn"
      v-model="navigationDrawer"
      temporary
      app
    >
      <v-list>
        <v-list-item
          v-for="item in leftNavItems"
          :key="item.path"
          link
          @click="goto(item.path)"
        >
          <v-list-item-icon class="d-flex">
            <v-icon
              class="mr-auto ml-auto"
              size="20"
            >
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data: () => ({
    navigationDrawer: false,
    leftNavItems: [
      {icon: "mdi-desktop-tower", path: "/inverters", label: "Falowniki"},
      {icon: "mdi-heat-pump", path: "/pumps", label: "Pompy"},
      {icon: "mdi-account-group", path: "/users", label: "Użytkownicy"},
      {icon: "mdi-chevron-triple-up", path: "/ranking", label: "Ranking"},
      {icon: "mdi-file-import", path: "/import", label: "Import"},
      {icon: "mdi-file-import", path: "/Trainings", label: "Szkolenia"},
      {icon: "mdi-file-import", path: "/orders", label: "Zlecenia"}
    ]
  }),
  computed: {
    getUser() {
      return this.$store.getters.getUser
    },
    userExist() {
      return this.$store.getters.userExist
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    loading() {
      return this.$store.getters.loading
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$store.dispatch("setEnums")
    }
  },
  methods: {
    goto(path) {
      this.$router.push({path: path})
    },
    logout() {
      const vm = this
      this.$http({
        method: "post",
        url: "Authentication/logout",
        skipLoading: true
      })
      this.$nextTick(() => {
        vm.$store.dispatch("logout")
      })
    },
    toggleTheme() {
      localStorage.setItem("isDark", !this.$vuetify.theme.dark)
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    }
  }
}
</script>

<style scoped></style>
