import * as types from "./mutation-types"
import jwtDecode from "jwt-decode"
export default {
  [types.LOADING](state) {
    state.loading = true
  },
  [types.LOADED](state) {
    state.loading = false
  },
  [types.LOGIN](state) {
    state.isLoggedIn = true
    state.tokenData = jwtDecode(localStorage.getItem("token"))
  },
  [types.LOGOUT](state) {
    state.isLoggedIn = false
    state.tokenData = null
  },
  [types.SET_ENUMS](state, enums) {
    state.enums = enums
  }
}
