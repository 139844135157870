import jwtDecode from "jwt-decode"

export default {
  loading: false,
  isLoggedIn: !!localStorage.getItem("token"),
  isDark: localStorage.getItem("isDark") === "true",
  tokenData: localStorage.getItem("token") ? jwtDecode(localStorage.getItem("token")) : null,
  actualLanguage: localStorage.getItem("locale") ?? "pl",
  enums: null
}
