import jwt_decode from "jwt-decode"

export const getTokenData = (token) => {
  if (token == null) {
    token = localStorage.getItem("token")
  }
  if (!token) {
    return null
  }
  return jwt_decode(token)
}

export const objectExist = (obj) => {
  return obj !== null ?? Object.keys(obj).length > 0
}

export const cutString = (text, index, end = true) => {
  return end ? text.substring(0, index) : text.substring(index)
}
