<template>
  <v-app>
    <NavBar/>

    <v-main dark>
      <v-container
          fluid
          class="app pa-7"
      >
        <v-progress-linear
            :active="loading"
            indeterminate
            absolute
            top
        />

        <transition
            name="fade"
            mode="out-in"
        >
          <router-view/>
        </transition>
      </v-container>
    </v-main>

    <notify/>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";

export default {
  name: "App",
  components: {NavBar},
  computed: {
    loading() {
      return this.$store.getters.loading
    }
  },
  mounted() {
    this.$vuetify.theme.dark = this.$store.state.isDark
  },
}
</script>

<style lang="sass">
.fade-enter-active, .fade-leave-active
  transition: opacity .3s

.fade-enter, .fade-leave-to
  opacity: 0

.app
  height: 100%

.theme--dark.v-application
  background-color: #1a1a1a !important

.p-relative
  position: relative
</style>