import * as types from "./mutation-types"
import Vue from "vue"
import router from "@/router"
import { getTokenData } from "@/helpers"
let loaderCount = 0

export const loading = ({ commit }) => {
  loaderCount++
  if (loaderCount > 0) {
    commit(types.LOADING)
  }
}

export const loaded = ({ commit }) => {
  setTimeout(() => {
    if (loaderCount > 0) {
      loaderCount--
    }
    if (loaderCount <= 0) {
      commit(types.LOADED)
    }
  }, 200)
}

export const login = ({ commit }, token) => {
  const data = getTokenData(token)
  if (parseInt(data.userRoleId) !== 9) {
    Vue.$notify.showWarn("Nie posiadasz dostępu do panelu administratora")
    return
  }
  localStorage.setItem("token", token)
  commit(types.LOGIN)
  setEnums({ commit })
  Vue.$notify.showSuccess("Zalogowano pomyślnie")
  router.push({ path: "/" })
}

export const logout = ({ commit }) => {
  localStorage.removeItem("token")
  commit(types.LOGOUT)
  setTimeout(() => {
    router.push({ path: "/authentication/login" })
  })
}

export const setEnums = async ({ commit }) => {
  const enums = (await Vue.$http.get("Enums")).data
  commit(types.SET_ENUMS, enums)
}
