export const loading = (state) => {
  return state.loading
}

export const isLoggedIn = (state) => {
  return state.isLoggedIn
}

export const getEnums = (state) => {
  return state.enums
}

export const getUser = (state) => {
  return state.tokenData
}

export const userExist = (state) => {
  return !!state.tokenData ?? Object.keys(state.tokenData).length > 0
}
