import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAppBar,{attrs:{"app":"","color":"primary","dark":""}},[(_vm.isLoggedIn)?_c(VAppBarNavIcon,{on:{"click":function($event){_vm.navigationDrawer = !_vm.navigationDrawer}}}):_vm._e(),_c(VToolbarTitle,[_c(VImg,{attrs:{"max-width":"100","src":require("@/assets/logo-white.png")}})],1),_c(VSpacer),(_vm.userExist)?_c(VChip,{staticClass:"ma-2",attrs:{"color":"primary"}},[_c(VAvatar,{attrs:{"left":""}},[_c(VIcon,[_vm._v("mdi-account-circle")])],1),_vm._v(" "+_vm._s(_vm.getUser.userName)+" ")],1):_vm._e(),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.toggleTheme}},[_c(VIcon,[_vm._v("mdi-theme-light-dark")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.logout}},[_c(VIcon,[_vm._v("mdi-exit-to-app")])],1)],1),(_vm.isLoggedIn)?_c(VNavigationDrawer,{attrs:{"temporary":"","app":""},model:{value:(_vm.navigationDrawer),callback:function ($$v) {_vm.navigationDrawer=$$v},expression:"navigationDrawer"}},[_c(VList,_vm._l((_vm.leftNavItems),function(item){return _c(VListItem,{key:item.path,attrs:{"link":""},on:{"click":function($event){return _vm.goto(item.path)}}},[_c(VListItemIcon,{staticClass:"d-flex"},[_c(VIcon,{staticClass:"mr-auto ml-auto",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.label)+" ")])],1)],1)}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }