import Vue from "vue"
import "@mdi/font/css/materialdesignicons.css" // Ensure you are using css-loader

import colors from "vuetify/lib/util/colors"
import pl from "vuetify/es5/locale/pl"
import Vuetify from "vuetify/lib"

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: "mdi" // default - only for display purposes
  },
  lang: {
    locales: { pl },
    current: "pl"
  },
  theme: {
    themes: {
      light: {},
      dark: {
        primary: colors.blue.darken3
      }
    }
  }
})
