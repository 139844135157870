import Vue from "vue"
import Axios from "axios"
import store from "@/store"

Axios.defaults.baseURL = window.location.protocol + "//" + window.location.host + "/api"
Axios.interceptors.request.use((config) => {
  if (!config.skipLoading) {
    store.dispatch("loading")
  }
  config.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token")
  return config
})

const errorDebounceList = []

function showErrorWithDebounce(message) {
  const find = errorDebounceList.find((el) => el.message === message)
  if (find == null) {
    const item = {
      message: message,
      timeout: setTimeout(() => {
        Vue.$notify.showError(message)
        const index = errorDebounceList.indexOf(item)
        errorDebounceList.splice(index, 1)
      }, 600)
    }
    errorDebounceList.push(item)
  }
}

Axios.interceptors.response.use(
  (response) => {
    store.dispatch("loaded")
    return response
  },
  (error) => {
    store.dispatch("loaded")
    if (error.response) {
      if (error.response.status == 400) {
        if (error.response.data.errorMessage != null) {
          Vue.$notify.showError(error.response.data.errorMessage)
        }
        if (error.response.data.message == "Connection not found") {
          setTimeout(() => {
            showErrorWithDebounce("Nie udało się uzyskać zdalnego połączenia")
          }, 100)
        }
        if (error.response.data.message == "General error") {
          setTimeout(() => {
            showErrorWithDebounce("Błąd podczas przetwarzania zapytania")
          }, 100)
        }
      }
      if (error.response.status == 401) {
        setTimeout(() => {
          Vue.$notify.showError("Sesja wygasła", "Twoja sesja wygasła, proszę zalogować się ponownie")
        }, 100)
        store.dispatch("logout")
      }
      if (error.response.status == 403) {
        store.dispatch("logout")
      }
    } else {
      showErrorWithDebounce("Błąd połączenia", "Wystąpił błąd podczas próby połączenia z serwerem")
    }
    return Promise.reject(error)
  }
)

Vue.$http = Axios
Vue.prototype.$http = Axios

export default Axios
