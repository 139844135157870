import Vue from "vue"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import moment from "moment"
import store from "./store"
import router from "./router"
import "./axios"
import "./global.css"

import notification from "@/notifications"

Vue.use(notification)
Vue.config.productionTip = false
Vue.prototype.moment = moment

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
