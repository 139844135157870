import Vue, { ref } from "vue"
import Router from "vue-router"
import store from "@/store"

Vue.use(Router)
import { getTokenData } from "@/helpers"

const routes = [
  {
    path: "*",
    name: "not-found",
    component: () => import("@/pages/NotFound")
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/pages/Inverters/Index"),
    beforeEnter: guardRoute
  },
  {
    path: "/payment-result",
    name: "payment-result",
    component: () => import("@/pages/Payment/Index")
  },
  {
    path: "/authentication/login",
    name: "authentication-login",
    component: () => import("@/pages/Authentication/Login")
  },
  {
    path: "/authentication/forgotPassword",
    name: "authentication-forgot-password",
    component: () => import("@/pages/Authentication/ForgotPassword")
  },
  {
    path: "/authentication/changePassword/:token",
    name: "authentication-change-token",
    component: () => import("@/pages/Authentication/ChangePassword")
  },
  {
    path: "/authentication/verifyEmail/:token",
    name: "authentication-verify-email",
    component: () => import("@/pages/Authentication/VerifyEmail")
  },
  {
    path: "/authentication/changeEmail/:token",
    name: "authentication-change-email",
    component: () => import("@/pages/Authentication/ChangeEmail")
  },
  {
    path: "/inverters",
    name: "inverters",
    component: () => import("@/pages/Inverters/Index"),
    beforeEnter: guardRoute
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/pages/Users/Index"),
    beforeEnter: guardRoute
  },
  {
    path: "/users/import",
    name: "import-users",
    component: () => import("@/pages/Users/ImportUsers"),
    beforeEnter: guardRoute
  },
  {
    path: "/users/rewardSend/:token",
    name: "users-reward-send",
    component: () => import("@/pages/Users/RewardSend"),
    beforeEnter: guardRoute
  },
  {
    path: "/import",
    name: "import",
    component: () => import("@/pages/Import/Index"),
    beforeEnter: guardRoute
  },
  {
    path: "/ranking",
    name: "ranking",
    component: () => import("@/pages/Ranking/Index"),
    beforeEnter: guardRoute
  },
  {
    path: "/trainings",
    name: "trainings",
    component: () => import("@/pages/Trainings/Index"),
    beforeEnter: guardRoute
  },
  {
    path: "/pumps",
    name: "pumps",
    component: () => import("@/pages/Pumps/PumpsPage"),
    beforeEnter: guardRoute
  },
  {
    path: "/pumps/:id",
    name: "pump",
    component: () => import("@/pages/Pumps/Pump"),
    beforeEnter: guardRoute
  },
  {
    path: "/orders",
    name: "order",
    component: () => import("@/pages/Orders/Index"),
    beforeEnter: guardRoute
  },
  {
    name: "processes-list",
    path: "/orders/:id/processes",
    component: () => import("@/pages/Orders/Processes"),
    beforeEnter: guardRoute
  },
  {
    name: "process-details",
    path: "/process/:id/details",
    props: true,
    component: () => import("@/pages/Orders/Process/Details")
  },
  {
    path: "/users/rewardSend/:token",
    component: () => import("@/pages/Users/RewardSend"),
    beforeEnter: guardRoute
  }
]

const router = new Router({
  base: "/",
  mode: "history",
  routes
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => router.go(0))
}

function guardRoute(to, from, next) {
  if (store.getters.isLoggedIn && getTokenData()?.userRoleId == 9) {
    next()
  } else {
    Vue.$notify.showError("Nie posiadasz dostępu do tego zasobu, zaloguj sie na odpowiednie konto")
    next({ path: "authentication/login" })
  }
}

export default router
