<template>
  <div class="notify-wrapper">
    <transition-group name="fade">
      <v-alert
        v-for="item in notifications"
        :key="item.key"
        :type="item.type"
        dismissible
        transition="fade"
        @input="close(item)"
      >
        <span class="title">{{ item.title }}</span><br><span v-html="item.text" />
      </v-alert>
    </transition-group>
  </div>
</template>

<script>
import {} from '@/notifications'

export default {
  data() {
    return {
      snackbar: true
    }
  },
  computed: {
    notifications() {
      return this.$notify.notifications.items
    }
  },
  methods: {
    close(item) {
      this.$notify.closeNotify(item)
    }
  }
}
</script>

<style scoped>
.notify-wrapper {
  position: fixed;
  bottom: 10px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  z-index: 99999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media only screen and (max-width: 772px) {
  .notify-wrapper {
    width: 95%;
  }
}
.notify-wrapper >>> v-alert__wrapper {
  display: flex;
}
.notify-wrapper >>> i {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
